dmx.Component('notifications', {

  attributes: {
    position: {
      type: String,
      default: 'top' // top, bottom
    },

    align: {
      type: String,
      default: 'right' // left, right, full
    },

    'offset-x': {
      type: Number,
      default: 15
    },

    'offset-y': {
      type: Number,
      default: 15
    },

    spacing: {
      type: Number,
      default: 10
    },

    opacity: {
      type: Number,
      default: .8
    },

    timeout: {
      type: Number,
      default: 5000 // How long the notify will display without any interaction (0 to make sticky)
    },

    'extended-timeout': {
      type: Number,
      default: 1000
    },

    'newest-on-top': {
      type: Boolean,
      default: false
    },

    'show-animation': {
      type: String,
      default: 'fadeIn'
    },

    'show-duration': {
      type: Number,
      default: 1000
    },

    'hide-animation': {
      type: String,
      default: 'fadeOut'
    },

    'hide-duration': {
      type: Number,
      default: 1000
    },

    'close-animation': {
      type: String,
      default: null
    },

    'close-duration': {
      type: Number,
      default: 0
    },

    'close-icon': {
      type: String,
      default: 'fa fa-times'
    },

    closable: {
      type: Boolean,
      default: false
    },

    'info-background': {
      type: String,
      default: '#2f96b4'
    },

    'info-color': {
      type: String,
      default: '#fff'
    },

    'info-icon': {
      type: String,
      default: 'fa fa-info'
    },

    'success-icon': {
      type: String,
      default: 'fa fa-check'
    },

    'success-background': {
      type: String,
      default: '#51a351'
    },

    'success-color': {
      type: String,
      default: '#fff'
    },

    'warning-icon': {
      type: String,
      default: 'fa fa-exclamation'
    },

    'warning-background': {
      type: String,
      default: '#f89406'
    },

    'warning-color': {
      type: String,
      default: '#fff'
    },

    'danger-icon': {
      type: String,
      default: 'fa fa-warning'
    },

    'danger-background': {
      type: String,
      default: '#bd362f'
    },

    'danger-color': {
      type: String,
      default: '#fff'
    }
  },

  methods: {
    clear: function() {
      this.clear();
    },

    msg: function(message, options) {
      this.show('msg', message, options);
    },

    info: function(message, options) {
      this.show('info', message, Object.assign({
        icon: this.props['info-icon'],
        color: this.props['info-color'],
        background: this.props['info-background']
      }, options));
    },

    success: function(message, options) {
      this.show('success', message, Object.assign({
        icon: this.props['success-icon'],
        color: this.props['success-color'],
        background: this.props['success-background']
      }, options));
    },

    warning: function(message, options) {
      this.show('warning', message, Object.assign({
        icon: this.props['warning-icon'],
        color: this.props['warning-color'],
        background: this.props['warning-background']
      }, options));
    },

    danger: function(message, options) {
      this.show('danger', message, Object.assign({
        icon: this.props['danger-icon'],
        color: this.props['danger-color'],
        background: this.props['danger-background']
      }, options));
    }
  },

  events: {
    click: CustomEvent
  },

  positionClasses: ['top', 'bottom', 'left', 'right', 'full'],

  render: function(node) {
    this.$node = node;
    node.textContent = '';
    node.classList.add('dmx-notifications');
    this.update({});
  },

  update: function(props) {
    if (this.props.position != props.position || this.props['offset-x'] != props['offset-x'] || this.props['offset-y'] != props['offset-y']) {
      this.$node.style.removeProperty('top');
      this.$node.style.removeProperty('bottom');
      this.$node.style.setProperty(this.props.position, this.props['offset-y'] + 'px');
      this.$node.style.setProperty('left', this.props['offset-x'] + 'px');
      this.$node.style.setProperty('right', this.props['offset-x'] + 'px');
    }
  },

  clear: function() {
    this.$node.textContent = '';
  },

  create: function(options) {
    var template = '<div class="dmx-notify">';
    template += '<div style="background: {{background}}; color: {{color}}; opacity: {{opacity}}">'
    if (options.closable) template += '<button type="button" aria-hidden="true" class="dmx-close"><i class="{{close-icon}}"></i></button>';
    if (options.icon) template += '<div class="dmx-icon"><i class="{{icon}}"></i></div>';
    if (options.title) template += '<div class="dmx-title">{{title}}</div>';
    if (options.message) template += '<div class="dmx-message">{{message}}</div>';
    template += '</div></div>';

    template = template.replace(/\{\{(.*?)\}\}/g, function(a, b) {
      return options[b];
    });

    var div = document.createElement('div');
    div.innerHTML = template;
    return div.firstChild;
  },

  show: function(type, message, options) {
    options = Object.assign({ type: type, message: message, background: '#f1f1f1', color: '#333' }, this.props, options);

    var notify = this.create(options);

    notify.style.setProperty(options.position == 'top' ? 'margin-bottom' : 'margin-top', options.spacing + 'px');

    notify.style.setProperty('float', options.align);
    notify.style.setProperty('clear', options.align);
    notify.style.setProperty('animation-name', options['show-animation']);
    notify.style.setProperty('animation-duration', options['show-duration'] + 'ms');

    notify.addEventListener('mouseenter', this.pause.bind(this, notify, options));
    notify.addEventListener('mouseleave', this.continue.bind(this, notify, options));
    notify.addEventListener('click', this.dispatchEvent.bind(this, 'click', { detail: options }));

    if (options.closable) {
      notify.querySelector('.dmx-close').addEventListener('click', this.close.bind(this, notify, options));
    }

    if (options.timeout) {
      notify.timeoutId = setTimeout(this.hide.bind(this, notify, options), options['show-duration'] + options.timeout);
    }

    if (options['newest-on-top']) {
      this.$node.insertBefore(notify, this.$node.firstChild);
    } else {
      this.$node.appendChild(notify);
    }
  },

  hide: function(notify, options) {
    if (options['hide-animation'] && options['hide-duration']) {
      notify.style.setProperty('animation-name', options['hide-animation']);
      notify.style.setProperty('animation-duration', options['hide-duration'] + 'ms');
      setTimeout(function() { notify.remove(); }, options['hide-duration']);
    } else {
      notify.remove();
    }
  },

  close: function(notify, options) {
    if (options['close-animation'] && options['close-duration']) {
      notify.style.setProperty('animation-name', options['close-animation']);
      notify.style.setProperty('animation-duration', options['close-duration'] + 'ms');
      setTimeout(function() { notify.remove(); }, options['close-duration']);
    } else {
      notify.remove();
    }
  },

  pause: function(notify, options) {
    clearTimeout(notify.timeoutId);
  },

  continue: function(notify, options) {
    if (options.timeout || options['extended-timeout']) {
      notify.timeoutId = setTimeout(this.hide.bind(this, notify, options), options['extended-timeout']);
    }
  }

});
